import { memo } from "react";
import PropTypes from "prop-types";

import Input from "../../../components/input/Input";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { euCountries } from "../../../utils/countries";

import styles from "./AccountFields.module.css";

const AccountFields = ({ handleChange, setFieldValue, handleBlur, values, errors, touched, initialValues }) => {
  return (
    <div className={styles.accountFields}>
      <div className={styles.businessPurchaseCheckbox}>
        <input
          type="checkbox"
          name="isBusinessPurchase"
          id="business-purchase"
          checked={values.isBusinessPurchase}
          onChange={handleChange}
          className={styles.inputBusinessPurchaseCheckbox}
        />
        <label htmlFor="business-purchase">I&apos;m purchasing as a business</label>
      </div>
      {values.isBusinessPurchase && (
        <Input
          label="VAT company *"
          name="vatcompany"
          value={values.isBusinessPurchase ? values.vatcompany : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.vatcompany && errors.vatcompany}
          readOnly={!!initialValues.vatcompany}
        />
      )}
      {values.isBusinessPurchase && (
        <Input
          label="Company name *"
          name="companyName"
          value={values.isBusinessPurchase ? values.companyName : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.companyName && errors.companyName}
          readOnly={!!initialValues.companyName}
        />
      )}
      <Input
        label="Full name *"
        name="fullName"
        value={values.fullName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.fullName && errors.fullName}
        readOnly={!!initialValues.fullName}
      />
      <div className={styles.addressGrid}>
        <CustomSelect
          label="Country *"
          name="country"
          options={euCountries}
          value={euCountries.filter(({ value }) => value === values.country)}
          onChange={(option) => setFieldValue("country", option ? option.value : "")}
          className={styles.selectCountry}
          isDisabled={!!initialValues.country}
          error={touched.country && errors.country}
        />
        <Input
          label="City *"
          name="city"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.city && errors.city}
          readOnly={!!initialValues.city}
        />
        <Input
          label="Street *"
          name="street"
          value={values.street}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.street && errors.street}
          readOnly={!!initialValues.street}
        />
        <Input
          label="House *"
          name="house"
          value={values.house}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.house && errors.house}
          readOnly={!!initialValues.house}
        />
        <Input
          label="Apartment"
          name="apartment"
          value={values.apartment}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={!!initialValues.apartment}
        />
        <Input
          label="Postal code *"
          name="postalCode"
          value={values.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.postalCode && errors.postalCode}
          readOnly={!!initialValues.postalCode}
        />
      </div>

      <Input
        label="Phone number *"
        name="phoneNumber"
        value={values.phoneNumber}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.phoneNumber && errors.phoneNumber}
        readOnly={!!initialValues.phoneNumber}
      />
      <Input
        label="Email *"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && errors.email}
        readOnly={!!initialValues.email}
      />
      <Input
        label="IP address"
        name="ipaddress"
        value={values.ipaddress}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.ipaddress && errors.ipaddress}
        readOnly={!!initialValues.ipaddress}
      />
    </div>
  );
};

export default memo(AccountFields);

AccountFields.propTypes = {
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  values: PropTypes.shape({
    country: PropTypes.string,
    fullName: PropTypes.string,
    street: PropTypes.string,
    house: PropTypes.string,
    apartment: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    vatcompany: PropTypes.string,
    companyName: PropTypes.string,
    isBusinessPurchase: PropTypes.bool,
    ipaddress: PropTypes.string,
  }),
  errors: PropTypes.shape({
    country: PropTypes.string,
    fullName: PropTypes.string,
    street: PropTypes.string,
    house: PropTypes.string,
    apartment: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    vatcompany: PropTypes.string,
    companyName: PropTypes.string,
    isBusinessPurchase: PropTypes.string,
    ipaddress: PropTypes.string,
  }),
  touched: PropTypes.shape({
    country: PropTypes.bool,
    fullName: PropTypes.bool,
    street: PropTypes.bool,
    house: PropTypes.bool,
    apartment: PropTypes.bool,
    postalCode: PropTypes.bool,
    city: PropTypes.bool,
    phoneNumber: PropTypes.bool,
    email: PropTypes.bool,
    vatcompany: PropTypes.bool,
    companyName: PropTypes.bool,
    ipaddress: PropTypes.bool,
  }),
  initialValues: PropTypes.shape({
    country: PropTypes.string,
    fullName: PropTypes.string,
    street: PropTypes.string,
    house: PropTypes.string,
    apartment: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    vatcompany: PropTypes.string,
    companyName: PropTypes.string,
    isBusinessPurchase: PropTypes.bool,
    ipaddress: PropTypes.string,
  }).isRequired,
};

AccountFields.defaultProps = {
  handleChange: () => {},
  setFieldValue: () => {},
  handleBlur: () => {},
  values: {
    country: "",
    fullName: "",
    street: "",
    house: "",
    apartment: "",
    postalCode: "",
    city: "",
    phoneNumber: "",
    email: "",
    vatcompany: "",
    companyName: "",
    isBusinessPurchase: true,
    ipaddress: "",
  },
  errors: {},
  touched: {},
};
