import * as yup from "yup";
import REQUIRED from "./errorsText";

export const AccountValidationSchema = yup.object().shape({
  country: yup.string().required(REQUIRED),
  fullName: yup.string().required(REQUIRED),
  street: yup.string().required(REQUIRED),
  house: yup.string().required(REQUIRED),
  postalCode: yup.string().required(REQUIRED),
  city: yup.string().required(REQUIRED),
  phoneNumber: yup.string().required(REQUIRED),
  email: yup.string().email("Invalid email").required(REQUIRED),
  isBusinessPurchase: yup.boolean().required(REQUIRED),
  vatcompany: yup.string().when("isBusinessPurchase", {
    is: true,
    then(schema) {
      return schema.required(REQUIRED);
    },
  }),
  companyName: yup.string().when("isBusinessPurchase", {
    is: true,
    then(schema) {
      return schema.required(REQUIRED);
    },
  }),
  ipaddress: yup
    .string()
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      "Invalid IP address"
    ),
});

export const initialValues = (
  country,
  fullName,
  street,
  house,
  apartment,
  postalCode,
  city,
  phoneNumber,
  email,
  vatcompany,
  companyName,
  isBusinessPurchase,
  ipaddress
) => ({
  country: country || "",
  fullName: fullName || "",
  street: street || "",
  house: house || "",
  apartment: apartment || "",
  postalCode: postalCode || "",
  city: city || "",
  phoneNumber: phoneNumber || "",
  email: email || "",
  vatcompany: vatcompany || "",
  companyName: companyName || "",
  isBusinessPurchase: isBusinessPurchase || true,
  ipaddress: ipaddress || "",
});
