import PropTypes from "prop-types";

const Button = (props) => {
  const { buttonName, buttonType, onClick, className, disabled } = props;

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={buttonType} onClick={onClick} className={className} disabled={disabled}>
      {buttonName}
    </button>
  );
};

Button.propTypes = {
  buttonName: PropTypes.string,
  buttonType: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  buttonName: "",
  buttonType: "button",
  onClick: () => {},
  className: "",
  disabled: false,
};

export default Button;
