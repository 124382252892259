/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { hidePopup, setVideoFile, showPopup } from "./syntheticSlice";
import "./synthetic.css";

function Synthetic({
  greetingVideo = "",
  videoClick = {
    videoPath: "",
    question: false,
  },
  onYesClick = {
    type: "",
    value: undefined,
  },
  onNoClick = {
    type: "",
    value: undefined,
  },
  onVideo = {
    videoPath: "",
    question: false,
  },
  onContinueClick = {
    videoPath: "",
    question: false,
  },
}) {
  const [config, setConfig] = useState(null);
  const isPopupVisible = useSelector((state) => state.synthetic.isPopupVisible);
  const videoFile = useSelector((state) => state.synthetic.videoFile);
  const dispatch = useDispatch();
  const location = useLocation();
  const videoRef = useRef(null);
  const animationClass = config?.position === "right" ? "animate-right" : "animate-left";
  const animationExitClass = config?.position === "right" ? "animate-out-right" : "animate-out-left";
  const [autoPlay, setAutoPlay] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [isCloseIconVisible, setIsCloseIconVisible] = useState(false);
  const [greetingVideoWatched, setGreetingVideoWatched] = useState(false);
  const [isFixedToFooter, setIsFixedToFooter] = useState(false);
  const [valueBottom, setValueBottom] = useState(0);

  const appendCacheBuster = (url) => {
    if (!url) return url;
    const cacheBuster = `nocache=${new Date().getTime()}`;
    return url.includes("?") ? `${url}&${cacheBuster}` : `${url}?${cacheBuster}`;
  };

  const onClose = () => {
    if (videoFile === greetingVideo) {
      setGreetingVideoWatched(true);
    }
    dispatch(hidePopup());
  };
  const handleGreetingVideo = () => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      if (video.id !== greetingVideo) {
        video.pause();
      }
    });
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(greetingVideo);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
  };

  const handleYesClick = () => {
    if (!onYesClick.value) {
      dispatch(hidePopup());
      setShowButtons(false);
    } else {
      const videoUrlWithCacheBuster = appendCacheBuster(onYesClick.value);
      dispatch(setVideoFile(videoUrlWithCacheBuster));
      setShowButtons(false);
    }
  };

  const handleNoClick = () => {
    if (!onNoClick.value) {
      dispatch(hidePopup());
      setShowButtons(false);
    } else {
      const videoUrlWithCacheBuster = appendCacheBuster(onNoClick.value);
      dispatch(setVideoFile(videoUrlWithCacheBuster));
      setShowButtons(false);
    }
  };

  const handleOnClick = () => {
    if (!videoClick.videoPath) {
      return;
    }
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(videoClick.videoPath);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
  };

  const handleOnVideo = () => {
    if (!onVideo.videoPath) {
      return;
    }
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(onVideo.videoPath);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
  };

  const handleContinueClick = () => {
    if (!onContinueClick.videoPath) {
      return;
    }
    setIsCloseIconVisible(true);
    const videoUrlWithCacheBuster = appendCacheBuster(onContinueClick.videoPath);
    dispatch(setVideoFile(videoUrlWithCacheBuster));
    dispatch(showPopup());
    setAutoPlay(true);
    setShowContinueButton(false);
  };

  const handleVideoEnded = () => {
    if (greetingVideo !== "" || !greetingVideoWatched) {
      onClose();
      setAutoPlay(false);
      setGreetingVideoWatched(true);
      return;
    }
    if (videoFile !== videoClick.videoPath) {
      if (videoClick.question) {
        setIsCloseIconVisible(false);
        setShowButtons(true);
      } else {
        onClose();
      }
    }
    // if (videoFile === onVideo.videoPath) {
    //   if (onVideo.question) {
    //     setIsCloseIconVisible(false);
    //     setShowButtons(true);
    //     console.log("question")
    //   } else {
    //     onClose();
    //     console.log("end")
    //   }
    // }
  };

  const handleVideoLoaded = () => {
    if (videoRef.current && videoRef.current.readyState === 4) {
      videoRef.current.play().catch((error) => {
        onClose();
        setGreetingVideoWatched(true);
        // eslint-disable-next-line no-console
        console.error("Error by load video:", error);
      });
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        // const response = await fetch("/config.json");
        // const configData = await response.json();
        setConfig({
          avatar: "/syntheticAvatar.webp",
          position: "right",
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (config) {
      handleGreetingVideo();
    }
  }, []);

  useEffect(() => {
    if (onVideo.videoPath !== "") {
      handleOnVideo();
    } else {
      dispatch(hidePopup());
    }
  }, [onVideo]);

  useEffect(() => {
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
      videoRef.current.load();
    }
  }, [videoFile, dispatch]);

  useEffect(() => {
    dispatch(hidePopup());
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById("footerBox");
      const footerOffsetTop = footer.offsetTop;
      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY;

      if (scrollTop + windowHeight >= footerOffsetTop) {
        setIsFixedToFooter(true);
        setValueBottom(scrollTop + windowHeight - footerOffsetTop);
      } else {
        setIsFixedToFooter(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return isPopupVisible ? (
    <div
      className={`synthetic-popup ${config?.position} ${isPopupVisible ? animationClass : animationExitClass}`}
      style={{ bottom: isFixedToFooter ? `${valueBottom}px` : "3%" }}
    >
      <video
        src={videoFile}
        preload="metadata"
        playsInline
        poster="/Dual-Ring.png"
        ref={videoRef}
        onLoadedData={handleVideoLoaded}
        autoPlay={autoPlay}
        className="avatar-video"
        onEnded={handleVideoEnded}
      >
      </video>
      <div className="popup-controls">{isCloseIconVisible && <i className="cl-btn-7" onClick={onClose} />}</div>
      {showButtons && (
        <div className="buttons-container">
          <button type="button" className="yes-button" onClick={handleYesClick}>
            Yes
          </button>
          <button type="button" className="no-button" onClick={handleNoClick}>
            No
          </button>
        </div>
      )}
      {/* <Spinner></Spinner> */}
      {showContinueButton && (
        <div className="continue-button-container">
          <button type="button" className="continue-button" onClick={handleContinueClick}>
            Continue
          </button>
        </div>
      )}
    </div>
  ) : (
    <button
      type="button"
      className={`round-button ${isPopupVisible ? "hidden" : ""}`}
      style={{ bottom: isFixedToFooter ? `${valueBottom}px` : "3%" }}
      onClick={handleOnClick}
    >
      <img src={config?.avatar} alt="Toggle Popup" />
    </button>
  );
}

export default Synthetic;
