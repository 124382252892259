import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useNavigate } from "react-router-dom";
import styles from "./errorWindow.module.css";

function ErrorWindow({ text = "", onClose }) {
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate("/account/mySynthetic");
    onClose();
  };

  return (
    <div className={styles.confirmWindow}>
      <div className={styles.calcByConfirmBox}>
        <div className={styles.windowName}>
          <WarningAmberIcon style={{ color: "#EC4A0A" }} />
          <h3>Error</h3>
        </div>
        <div>{text}</div>
        <div className={styles.btnBoxByConfirm}>
          <button className={styles.button} onClick={onButtonClick} type="button">
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorWindow;
